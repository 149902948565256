/* eslint-disable max-len */
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import style from './Availability.module.scss';
import { Icon } from '../../../components/atoms';
import { Dialog } from '../../../components/atoms/Dialog/Dialog';
import type { Slot as SlotType } from '../../../types/slot.type';
import { removeSlot } from './availabilitySlice';
import { PracticeService } from '../../../services';

const cx = classNames.bind(style);
const TextLabel = cx('TextLabel');

export default function Slots({ isAllowedToSetFirstPresentationSlots = true, ...props }: any): JSX.Element {
  const hours = ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];
  const minutes = ['00', '15', '30', '45'];

  const [slotToDelete, setSlotToDelete] = useState<SlotType>();
  const [showDialog, setShowDialog] = useState(false);
  const [showAppointmentExistsDialog, setShowAppointmentExistsDialog] = useState(false);
  const dispatch = useDispatch();
  const [allPractices, setAllPractices] = useState<any[]>([]);

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const response = await PracticeService.getAllPractices();
        setAllPractices(response.data);
      } catch (error) {
        console.error('Error fetching practice data:', error);
      }
    };
    fetchPractices();
  }, []);

  const deleteSlot = (slot: SlotType) => {
    const hasBookedAppointmentOnSlot = props.appointmentsTest.filter(
      (element: { startDate: string | number | Date; }) => new Date(element.startDate).getTime() >= new Date(slot.startDate).getTime()
        && new Date(element.startDate).getTime() < new Date(slot.endDate).getTime(),
    );

    if (hasBookedAppointmentOnSlot.length !== 0) {
      setShowAppointmentExistsDialog(true);
      setSlotToDelete(slot);

      return;
    }

    setShowDialog(true);
    setSlotToDelete(slot);
  };

  const onConfirm = async () => {
    if (slotToDelete?.tempID && !slotToDelete?.id) {
      const newArr = props.selectedDateSlots.filter((slot: { tempID: string | undefined; }) => slot.tempID !== slotToDelete.tempID);
      props.setSelectedDateSlots([...newArr]);
      setShowDialog(false);

      return;
    }

    if (slotToDelete?.id === undefined || !slotToDelete?.id) {
      return;
    }

    const payload: any = { slotToDeleteId: slotToDelete.id };
    dispatch(removeSlot(payload));

    const newArr = props.selectedDateSlots.filter((slot: { id: string | undefined; }) => slot.id !== slotToDelete.id);
    props.setSelectedDateSlots([...newArr]);
    setShowDialog(false);
    setShowAppointmentExistsDialog(false);
  };

  const onCancel = () => {
    setShowDialog(false);
    setShowAppointmentExistsDialog(false);
  };

  return (
    <>{props.selectedDateSlots.map((slot: SlotType, index: string | number) => (
      <div className={style.RowContainer} key={slot.id || slot.tempID}>
        <div className={style.ColumnContainer}>
          <p className={style.TextLabel}>Typ</p>
          <select
            className={style.InputClass}
            value={slot.practiceId !== undefined ? String(slot.practiceId) : 'default'}
            onChange={(event) => {
              const newArr = _.cloneDeep(props.selectedDateSlots);
              newArr[index].practiceId = Number(event.target.value);
              props.setSelectedDateSlots(newArr);
            }}
          >
            <option value={'default'} disabled></option>
            <option value={0}>Online</option>
            {allPractices.map((practice, i) => (
              <option key={`practice-${practice.id}-${i}`} value={practice.id}>
                {practice.name}
              </option>
            ))}
          </select>
        </div>

        <div className={style.ColumnContainer}>
          <p className={TextLabel}>Beginn</p>
          <div className={style.UhrRowContainer}>
            <select
              className={style.UhrInputClass}
              defaultValue={
                slot.startDate ? String(new Date(slot.startDate).getHours()).padStart(2, '0') : 'default'
              }
              onChange={(event) => {
                const slotID = slot.id || slot.tempID;
                const foundSlot = props.selectedDateSlots.find(
                  (element: { id: any; tempID: any; }) => element.id === slotID || element.tempID === slotID,
                );
                const slotIndex = props.selectedDateSlots.map((e: { id: any; tempID: any; }) => e.id || e.tempID).indexOf(String(slotID));

                if (foundSlot?.startDate) {
                  const newStartDate = new Date(foundSlot?.startDate).setHours(Number(event.target.value));
                  const newObj = { ...foundSlot };
                  newObj.startDate = String(new Date(newStartDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.startDate && !foundSlot?.startDateSetMin) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  newObj.startDateSetHour = event.target.value;

                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;

                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.startDate && foundSlot?.startDateSetMin) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  const currentDate = new Date(
                    props.selectedMonth.getFullYear(),
                    props.selectedMonth.getMonth(),
                    Number(props.selectedDate.slice(0, 2)),
                  );
                  const newStartDate = new Date(currentDate).setHours(
                    Number(event.target.value),
                    Number(foundSlot.startDateSetMin),
                  );
                  newObj.startDate = String(new Date(newStartDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
              }}
            >
              <option value={'default'} disabled></option>
              {hours.map((value) => <option key={value}>{value}</option>)}
            </select>
            <select
              className={style.UhrInputClass}
              defaultValue={slot.startDate ? String(new Date(slot.startDate).getMinutes()) : 'default'}
              onChange={(event) => {
                const slotID = slot.id || slot.tempID;
                const foundSlot = props.selectedDateSlots.find(
                  (element: { id: any; tempID: any; }) => element.id === slotID || element.tempID === slotID,
                );
                const slotIndex = props.selectedDateSlots.map((e: { id: any; tempID: any; }) => e.id || e.tempID).indexOf(String(slotID));

                if (foundSlot?.startDate) {
                  const newStartDate = new Date(foundSlot?.startDate).setMinutes(Number(event.target.value));
                  const newObj = { ...foundSlot };
                  newObj.startDate = String(new Date(newStartDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
                if (!foundSlot?.startDate && !foundSlot?.startDateSetHour) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  newObj.startDateSetMin = event.target.value;

                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;

                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.startDate && foundSlot?.startDateSetHour) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  const currentDate = new Date(
                    props.selectedMonth.getFullYear(),
                    props.selectedMonth.getMonth(),
                    Number(props.selectedDate.slice(0, 2)),
                  );
                  const newStartDate = new Date(currentDate).setHours(
                    Number(foundSlot.startDateSetHour),
                    Number(event.target.value),
                  );
                  newObj.startDate = String(new Date(newStartDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
              }}
            >
              <option value={'default'} disabled></option>
              {minutes.map((value) => <option key={value}>{value}</option>)}
            </select>
            <p className={style.UhrInputLabel}>Uhr</p>
          </div>
        </div>
        <div className={style.ColumnContainer}>
          <p className={TextLabel}>Ende</p>
          <div className={style.UhrRowContainer}>
            <select
              className={style.UhrInputClass}
              defaultValue={
                slot.endDate ? String(new Date(slot.endDate).getHours()).padStart(2, '0') : 'default'
              }
              onChange={(event) => {
                const slotID = slot.id || slot.tempID;
                const foundSlot = props.selectedDateSlots.find(
                  (element: SlotType) => element.id === slotID || element.tempID === slotID,
                );
                const slotIndex = props.selectedDateSlots.map((e: { id: any; tempID: any; }) => e.id || e.tempID).indexOf(String(slotID));

                if (foundSlot?.endDate) {
                  const newEndDate = new Date(foundSlot?.endDate).setHours(Number(event.target.value));
                  const newObj = { ...foundSlot };
                  newObj.endDate = String(new Date(newEndDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.endDate && !foundSlot?.endDateSetMin) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  newObj.endDateSetHour = event.target.value;

                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;

                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.endDate && foundSlot?.endDateSetMin) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  const currentDate = new Date(
                    props.selectedMonth.getFullYear(),
                    props.selectedMonth.getMonth(),
                    Number(props.selectedDate.slice(0, 2)),
                  );
                  const newEndDate = new Date(currentDate).setHours(
                    Number(event.target.value),
                    Number(foundSlot.endDateSetMin),
                  );
                  newObj.endDate = String(new Date(newEndDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
              }}
            >
              <option value={'default'} disabled></option>
              {hours.map((value) => <option key={value}>{value}</option>)}
            </select>
            <select
              className={style.UhrInputClass}
              defaultValue={slot.endDate ? String(new Date(slot.endDate).getMinutes()) : 'default'}
              onChange={(event) => {
                const slotID = slot.id || slot.tempID;
                const foundSlot = props.selectedDateSlots.find(
                  (element: { id: any; tempID: any; }) => element.id === slotID || element.tempID === slotID,
                );
                const slotIndex = props.selectedDateSlots.map((e: { id: any; tempID: any; }) => e.id || e.tempID).indexOf(String(slotID));

                if (foundSlot?.endDate) {
                  const newEndDate = new Date(foundSlot?.endDate).setMinutes(Number(event.target.value));
                  const newObj = { ...foundSlot };
                  newObj.endDate = String(new Date(newEndDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
                if (!foundSlot?.endDate && !foundSlot?.endDateSetHour) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  newObj.endDateSetMin = event.target.value;

                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;

                  props.setSelectedDateSlots([...newArr]);

                  return;
                }
                if (!foundSlot?.endDate && foundSlot?.endDateSetHour) {
                  const newObj = props.selectedDateSlots[slotIndex];
                  const currentDate = new Date(
                    props.selectedMonth.getFullYear(),
                    props.selectedMonth.getMonth(),
                    Number(props.selectedDate.slice(0, 2)),
                  );
                  const newEndDate = new Date(currentDate).setHours(
                    Number(foundSlot.endDateSetHour),
                    Number(event.target.value),
                  );
                  newObj.endDate = String(new Date(newEndDate));
                  const newArr = props.selectedDateSlots;
                  newArr[slotIndex] = newObj;
                  props.setSelectedDateSlots([...newArr]);
                }
              }}
            >
              <option value={'default'} disabled></option>
              {minutes.map((value) => <option key={value}>{value}</option>)}
            </select>
            <p className={style.UhrInputLabel}>Uhr</p>
          </div>
        </div>
        <div className={style.DeleteContainer} onClick={() => deleteSlot(slot)}>
          <Icon icon={'trash'} size={16} color={'rgb(220, 0, 0)'} />
          <p className={style.DeleteText}>entfernen</p>
        </div>
      </div>
    ))}

    {showDialog && (
      <Dialog
        message={'Möchten Sie diesen Slot wirklich entfernen?'}
        type='negativeDialog'
        buttonText='Ja'
        negativeButtonText={'Nein'}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    )}
    {showAppointmentExistsDialog && (
      <Dialog
        messageHead={'Sie haben zu dieser Zeit einen Termin!'}
        message={
          'Wenn Sie die Verfügbarkeit entfernen, bleiben die Termine erhalten. Zum Umbuchen oder Absagen der Termine wenden Sie sich bitte an den nowomed-Support.'
        }
        type='negativeDialog'
        buttonText='Slot entfernen'
        negativeButtonText={'Abbrechen'}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    )}
    </>

  );
}
